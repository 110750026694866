import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
const EntityDesc = ({ setShow }) => {
    const { data } = useSelector(state => state.document);
    return (
        <div className='desc-container'>
            <CloseIcon onClick={() => {
                setShow(pre => !pre);
            }} />
            <div className='img-container'>
                <div className="img-user">
                    <img src={`${process.env.REACT_APP_BACKEND_URL}/${data.imagePath}`} alt="" />
                </div>
                <h2>{data?.fileName}</h2>
                <p>Holan, Israel</p>
            </div>
            <div className='description'>
                <h3>Description</h3>
                <div className='dsc-container'>
                    <p>BigTalk visual studio</p>
                    <p>BigTalk visual studio</p>
                    <p>BigTalk visual studio</p>
                    <p>BigTalk visual studio</p>
                </div>
            </div>

            <div className="most-asked-q">
                <h3>Most Asked Questions</h3>
                <div className='ques-container'>
                    <p>BigTalk visual studio</p>
                    <p>BigTalk visual studio</p>
                    <p>BigTalk visual studio</p>
                    <p>BigTalk visual studio</p>
                </div>
            </div>
            <div></div>
        </div>
    )
}
export default EntityDesc;