import React, { useState,useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Grid, Typography, InputLabel, MenuItem, FormControl, Select, FormGroup, FormControlLabel, Checkbox } from "@mui/material";

import { useSignUserMutation, useGetOrganizationsQuery } from "../../app/services/jsonServerApi";
import { notification } from "../../service/index";
import { doLogin } from "../../reducers/app";
import TextField from "../common/TextField";
import CustomLink from "../common/CustomLink";
import SubmitButton from "../common/SubmitButton";

const schema = yup
  .object({
    email: yup.string().email().required("Please Enter email"),
    confirmEmail: yup.string().email().oneOf([yup.ref('email'), null], "Emails must match").required('please enter your confirm email'),
    username: yup.string().required('enter username').matches(/^[a-zA-Z]([a-zA-Z0-9]*_?[a-zA-Z0-9]+){2,9}$/, 'Username must start with a letter and be between 3 and 10 characters'),
    password: yup
      .string()
      .required("Please enter your password")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    showBtn: yup.boolean(),
    existOrg: yup.string().when('showBtn', {
      is: true,
      then: yup => yup.required("Please select a organization"),
      otherwise: (yup) => yup.notRequired()
    }),
    newOrg: yup
      .string()
      .when('showBtn', {
        is: false,
        then: (yup) => yup.required("Please enter new Organization"),
        otherwise: (yup) => yup.notRequired()
      })
  })

const SignUp = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createUser, { isLoading }] = useSignUserMutation();
  const [show, setShow] = useState(false);
  const [org, setOrg] = React.useState('');
  const { data, error } = useGetOrganizationsQuery();
  
  const handleChange = (event) => {
    setOrg(event.target.value);
  };

  const onFormSubmit = (v) => {
    const bodyData = { email: v.email, confirmEmail: v.confirmEmail, username: v.username, password: v.password, organization: show ? v.existOrg : v.newOrg }
    createUser(bodyData)
      .then((value) => {
        if (value?.error?.data?.message === 'Organization already exists!') {
          notification({
            title: "Duplicate organization",
            message: "Organization already exists!",
            type: "danger",
          });
        }
        else if (value?.error?.status === 400) {
          notification({
            title: "User Exists",
            message: "User is already exists",
            type: "danger",
          });
        } else if (value?.data?.result?.status === 200) {
          const {
            data: {
              token,
              result: { user },
            },
          } = value;
          notification({
            title: "Success",
            message: "Successfully Registered",
            type: "success",
          });
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(user));
          dispatch(doLogin({ token, user }));
          navigate("/");
        }
        else if (value?.data?.result?.status === 500) {
          notification({
            title: "Error",
            message: "something went wrong",
            type: "danger",
          });
        }
        else if (value?.error) {
          notification({
            title: "Error",
            message: value?.error?.status,
            type: "danger",
          });
        }
      })
      .catch((err) => {
        notification({ title: "Error", message: err.message, type: "danger" });
      });
  };

  return (
    <div className="login-signup-bg">
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <div className="form-wrapper">
            {/* <Typography
              variant="h4"
              style={{
                marginBottom: 10,
                textAlign: "center",
                color: "#1346a3",
              }}>
              Register
            </Typography> */}
            <form className="signup-form" onSubmit={handleSubmit(onFormSubmit)}>

            <TextField
                label="Username"
                placeholder="Enter your username"
                customId="username"
                type="text"
                register={register}
                errors={errors?.username?.message}
              />

              <TextField
                label="Email"
                placeholder="Enter your email"
                customId="email"
                type="text"
                register={register}
                errors={errors?.email?.message}
              />
              <TextField
                label="Confirm Email"
                placeholder="Enter your confirm email"
                customId="confirmEmail"
                type="text"
                register={register}
                errors={errors?.confirmEmail?.message}
              /> 
        
              <TextField
                label="Password"
                placeholder="Enter Password"
                customId="password"
                type="password"
                register={register}
                errors={errors?.password?.message}
              />




              <FormGroup>
                <FormControlLabel control={<Checkbox onClick={() => { setShow(!show) }} {...register('showBtn')} id="showBtn" value={show} />} label="Existing Organization" />
              </FormGroup>
              {show ?
                <>
                  <FormControl sx={{ m: 0, minWidth: 120 }} size="small" variant="filled">
                    <InputLabel id="demo-select-small-label">Organization</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="existOrg"
                      value={org}
                      label="Organization"
                      {...register('existOrg')}
                      onChange={handleChange}
                    >
                      {(data || []).map((item) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>)
                      )}
                    </Select>
                  </FormControl>
                  {errors?.existOrg?.message && <p style={{ color: 'red' }}>{errors?.existOrg?.message}</p>}
                </> :
                <TextField
                  label="Organization"
                  placeholder="Enter organization name"
                  customId="newOrg"
                  type="text"
                  register={register}
                  errors={errors?.newOrg?.message}
                />}
              <SubmitButton label={"Submit"} />
              
              <CustomLink
                link={"#"}
                text="Wachtwoord vergeten?"
                label={"Rest het hier"}
              />

            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignUp;
