import {configureStore} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import app from '../reducers/app';
import sidebar from '../reducers/sidebar';
import document from '../reducers/document';
import {jsonServerApi} from './services/jsonServerApi';

export const store=configureStore({
    reducer:{
        [jsonServerApi.reducerPath]:jsonServerApi.reducer,
        app,
        sidebar,
        document
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(jsonServerApi.middleware),
});

setupListeners(store.dispatch);