import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: {}
}

const document = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setDocument: (state, action) => {
            state.data = action.payload
        },
        resetDocument: (state) => {
            state.data = {}
        },
    }
});

export const { setDocument, resetDocument } = document.actions;

export default document.reducer;