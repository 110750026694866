import { useSelector } from "react-redux";
export const useAuth = () => {
    const { user } = useSelector((state) => state.app);
    let permissions = {
        isAdmin: false,
    };
    if (user) {
        const { isAdmin } = user;
        if (isAdmin) {
            permissions.isAdmin = true
        }
    }
    return permissions;
} 