import React from "react";
import { Link } from "react-router-dom";

const CustomLink = ({ link, label, text='' }) => {
    return (
        <div style={{ padding: '10px 0px'}} className="forgets-pass">
            <span>{text}<Link to={link} className="ml-2">{label}</Link></span>
        </div>
    )
}

export default CustomLink;