import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setDocument } from "../../reducers/document";

const Card = ({ item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.setItem('document', JSON.stringify(item));
    dispatch(setDocument(item));
    navigate(`chat/${item.fileName.replace(' ', '-').toLowerCase()}`);
  }
  return (
    
      <div className="card" onClick={handleClick}>
        <div className="cart_img_b">
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}/${item.imagePath}`}
            alt="Car"
          />
        </div>
        <h5 className="text-center text-dark">
          <b>{item.fileName}</b>
        </h5>
      </div>
  );
};

export default Card;
