import React, { useState,useEffect } from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';

import { useGetDocumentsQuery, } from "../app/services/jsonServerApi";
import Card from "./cards/Card";
import Loader from "./common/Loader";
import { useTranslation } from 'react-i18next';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
const DocumentsList = () => {
    const { searchParams } = useSelector(state => state.app);
    const [pageNumber, setPageNumber] = useState(0);
    const limit = 10;
    const offSet = pageNumber * limit;
    const { data: documents, isLoading, isFetching, error } = useGetDocumentsQuery({ searchParams, pageNumber, offSet }, { refetchOnMountOrArgChange: true });

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    }

    useEffect(() => {
        setPageNumber(0);
    }, [searchParams]);

    if (isLoading || isFetching) {
        return <Loader size={60} background="#f8f9fc" height="75vh" />
    }
    return (
        <>
            <div className="card_wrapper_s">
                {!error && documents && documents?.rows?.length ? documents?.rows?.map((item, key) =>
                    <Card item={item} key={key} />)
                    : <div style={{ paddingLeft: "20px" }}>
                        <p>Please contact</p>
                        <Link to={'#'} onClick={(e) => {
                            window.location = process.env.REACT_APP_OWNER_EMAIL;
                        }}>info@mijndata.ai</Link>
                    </div>
                }
            </div>
            <div className="pagination_container">
                <ReactPaginate
                    pageCount={!error && Math.ceil(documents?.count / 10)}
                    nextLabel={<ArrowForwardIosIcon />}
                    previousLabel={<ArrowBackIosNewIcon />}
                    onPageChange={changePage}
                    forcePage={pageNumber}
                    containerClassName="paginationBttn"
                    previousLinkClassName="previousBttn"
                    nextLinkClassName="nextBttn"
                    disabledClassName="paginationDisabled"
                    activeClassName="paginationActive"
                    renderOnZeroPageCount={null}
                />
            </div>
        </>
    )
}

export default DocumentsList;