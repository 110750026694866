import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

console.log(process.env.REACT_APP_BACKEND_URL);
export const jsonServerApi = createApi({
    reducerPath: "jsonServerApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().app.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        }
    }),
    tagTypes: ['Documents'],
    endpoints: (builder) => ({
        loginUser: builder.mutation({
            query: (postdata) => ({
                url: "user/login",
                method: "POST",
                body: postdata
            })
        }),
        signUser: builder.mutation({
            query: (postdata) => ({
                url: "user/signup",
                method: "POST",
                body: postdata
            })
        }),
        getDocuments: builder.query({
            query: ({searchParams,pageNumber,offSet}) => ({
                url: `/file/documents?page=${pageNumber}&search=${searchParams?searchParams:null}&offSet=${offSet}`,
                method: 'GET',
            }),
            providesTags: ['Documents']
        }),
        getOrganizations: builder.query({
            query: () => ({
                url: `/organization/getAll`,
                method: 'GET',
            }),
            keepUnusedDataFor:0.0001
        }),
        upLoadfile: builder.mutation({
            query: (postdata) => ({
                url: "file/upload",
                method: "POST",
                body: postdata
            }),
            invalidatesTags: ['Documents']
        }),
        chat: builder.mutation({
            query: (data) => ({
                url: 'file/chat',
                method: 'POST',
                body: data
            })
        }),
    }),
});

export const { useLoginUserMutation, useSignUserMutation, useUpLoadfileMutation, useGetDocumentsQuery, useChatMutation, useChatCloseMutation,useGetOrganizationsQuery } = jsonServerApi;