import React, { useState } from "react";
import { Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import AbstractModal from '../AbstractModal';

import TextField from "../common/TextField";
import SubmitButton from "../common/SubmitButton";
import { useUpLoadfileMutation, useGetOrganizationsQuery } from '../../app/services/jsonServerApi';
import { notification } from "../../service";
import ImageCrop from "../Image-crop/ImageCrop";

const schema = yup
    .object({
        docName: yup.string().required("Please Enter Organization Name"),
        imageFile: yup
            .mixed(),
        pdfFile: yup
            .mixed(),
        organization: yup.string().required("Please select organization")
    })
    .required();

const UploadPdfForm = () => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [upload, { isLoading, error }] = useUpLoadfileMutation();
    const [imageName, setImageName] = useState({});
    const [docName, setDocName] = useState(null);
    const [err, setErr] = useState({ img: false, pdf: false });
    const { data: organizations = [] } = useGetOrganizationsQuery();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({ resolver: yupResolver(schema) });

    const onFormSubmit = (v) => {
        if (!docName || !imageName.imageFile) {
            setErr({pdf: !docName, img: !imageName?.imageFile });
            return;
        }
        const formdata = new FormData();
        formdata.append('fileName', v.docName);
        formdata.append('organization', v.organization);
        formdata.append('files', imageName?.imageFile);
        formdata.append('files', v.pdfFile[0]);
        upload(formdata).then((result) => {
            if (result?.error?.error) {
                return notification({ type: "danger", message: result?.error?.error, title: "Error" })
            }
            notification({ type: "success", message: "document successfully uploaded", title: "success" });
            navigate('/');
        })
    };

    const handleClose = () => {
        setShow(prev => !prev);
    }

    return (
        <div className="uploaded-document">
            <AbstractModal Component={<ImageCrop handleClose={handleClose} setImageName={setImageName} />} show={show} handleClose={handleClose} />
            <Typography
                variant="h4"
                style={{ marginBottom: 10, textAlign: "center" }}>
                Upload A Document
            </Typography>
            <form className="py-3" onSubmit={handleSubmit(onFormSubmit)}>
                <TextField
                    color={errors?.docName?.message ? 'red' : ''}
                    label="Document Name"
                    placeholder="Enter Document Name"
                    customId="docName"
                    type="text"
                    register={register}
                    errors={errors?.docName?.message}
                />
                <div style={{ display: "flex", flexDirection: "column" }} className="img-select">
                    <label htmlFor="imageFile" style={{ color: "#1346a3", fontWeight: "bold", borderColor: err?.img && !imageName?.imageFile ? 'red' : '' }} onClick={handleClose}>
                        {(imageName?.imageFile && imageName.name) || "Select Image"}
                    </label>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }} className="img-select">
                    <label htmlFor="pdfFile" style={{ color: "#1346a3", fontWeight: "bold", borderColor: err?.pdf && !docName ? 'red' : '' }}>
                        {docName || "Select PDF"}
                    </label>
                    <input
                        type="file"
                        for="file"
                        id="pdfFile"
                        name="pdfFile"
                        accept=".pdf"
                        className="my-2"
                        onInput={(e) => {
                            setDocName(e.target?.files[0]?.name);
                        }}
                        {...register("pdfFile")}
                    />
                    {/* {errors?.pdfFile?.message && <p>{errors?.pdfFile?.message}</p>} */}
                </div>
                <FormControl sx={{ m: 0, minWidth: 120, width: 300 }} size="small" variant="filled" >
                    <InputLabel id="demo-select-small-label">Organization</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="organization"
                        label="Organization"
                        {...register('organization')}
                    >
                        {organizations?.map((item) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>)
                        )}
                    </Select>
                </FormControl>
                {errors?.organization?.message && <p style={{ color: 'red', position: 'relative', top: '-20px' }}>{errors?.organization?.message}</p>}
                {
                    isLoading ? <Box className="d-flex justify-content-center mt-2">
                        <CircularProgress />
                    </Box> :
                        <SubmitButton label="Upload Document" />
                }
            </form>
        </div>
    );
};

export default UploadPdfForm;