import { Routes, BrowserRouter as Router, Route } from 'react-router-dom';
import React from 'react';
import PrivateRoute from './components/PrivateRoutes';
import UploadPdfForm from './components/Forms/UploadForm';

// forms
import Register from './components/Register';

// Page
import Dashboard from './components/HomePage';
import Chat from './components/Chat';

//Hooks
import { useAuth } from './hooks/useAuth';

const Routers = ({ auth }) => {
  const { isAdmin } = useAuth();
  return (
    <div>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/signup" element={<Register />} />

          {/* Private Routes */}
          <Route path="/" element={<PrivateRoute auth={auth} Component={<Dashboard />} />} />
          { isAdmin && <Route path="/uploadDoc" element={<PrivateRoute auth={auth} Component={<UploadPdfForm />} />} />}
          <Route path="/chat/:documentName" element={<PrivateRoute auth={auth} Component={<Chat />} />} />
        </Routes>
      </Router>
    </div>
  )
}

export default Routers;