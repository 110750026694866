import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    token: null,
    user: null,
    searchParams: '',
}

const app = createSlice({
    name: 'app',
    initialState,
    reducers: {
        doLogin: (state, action) => {
            state.token = action.payload.token
            state.user = action.payload.user
        },
        doLogout: (state) => {
            state.token = null
            state.user = null
        },
        doSearch: (state, action) => {
            state.searchParams = action.payload
        },
        clearSearch: (state) => {
            state.searchParams = ''
        }
    }
});

export const { doLogin, doLogout, doSearch, clearSearch } = app.actions;

export default app.reducer;