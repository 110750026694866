import React, { useState } from "react";
// import { Button } from "react-bootstrap";
// import FileUploadIcon from "@mui/icons-material/FileUpload";
// import { useAuth } from "../hooks/useAuth";
import AbstractModal from "./AbstractModal";
import UploadPdfForm from "./Forms/UploadForm";
import DocumentsList from "./DocumentsList";

const Dashboard = () => {
  // const { isAdmin } = useAuth();
  const [show, setShow] = useState(false);

  return (
    <div className="page-container">
      <div className="contain_24">
        <div className="content-box">
          <DocumentsList />
        </div>
      </div>
      <AbstractModal
        show={show}
        handleClose={() => setShow(!show)}
        Component={<UploadPdfForm handleClose={() => setShow(!show)} />}
      />
    </div>
  );
};
export default Dashboard;