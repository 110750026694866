import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loader = ({ size = 100, background = '#333',height='100vh' }) => {
    return (
        <div className='custom_loader' style={{ background,height }}>
            <Box sx={{ display: 'flex' }}>
                <CircularProgress color='secondary' size={size} />
            </Box>
        </div>
    )
}

export default Loader;
