import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
const AbstractModal = ({
  show,
  handleClose,
  Component,
  heading,
  ...restProps
}) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className="position-relative close-modals">
          <span
            className="position-absolute"
            style={{ top: 2, right: 5, cursor: "pointer" }}
            onClick={handleClose}>
            <CloseIcon />
          </span>
        </Modal.Header>
        <Modal.Body>
          {Component}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AbstractModal;
