import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./app/store.js";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_en from "./constants/en/global.json";
import global_dutch from "./constants/dutch/global.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  global:{
     
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);
