import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.js";
import 'react-image-crop/dist/ReactCrop.css';
import './App.css';
import Routers from './router';
import { doLogin } from './reducers/app';
import { setDocument } from './reducers/document';
import Loader from './components/common/Loader';


const App = () => {

  const [isLoggedIn, setLogin] = useState(false);
  const [isComponentReady, setComponentReady] = useState(false);

  const dispatch = useDispatch();
  const { token } = useSelector(state => state.app);

  useEffect(() => {
    if (!token) {
      const _token = localStorage.getItem('token');
      const user = localStorage.getItem('user');
      const document = localStorage.getItem('document');
      if (_token && user) {
        dispatch(doLogin({ token: _token, user: JSON.parse(user) }));
        dispatch(setDocument(JSON.parse(document)));
        setLogin(true);
      }
      setComponentReady(true);
    } else {
      setLogin(true);
      setComponentReady(true);
    }
  }, [token]);

  return (
    <div className='main-login-data'>{isComponentReady ? <><ReactNotifications /><Routers auth={isLoggedIn} /></> : <Loader />}</div>
  )
}
export default App;