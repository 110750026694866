import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { InputGroup, Form, Button } from 'react-bootstrap';
import SearchIcon from '@mui/icons-material/Search';

import { doSearch } from "../reducers/app";

const Header = ({ collapsed }) => {
    const [search, setSearch] = useState();
    const dispatch = useDispatch();

    return (
        <div id="top-search-header" className="d-flex justify-content-between align-items-center py-2 shadow-lg p-2" >
            <div className="w-50">
                <InputGroup>
                    <Form.Control
                        placeholder="Search for..."
                        aria-label="Search"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <Button variant="primary" onClick={() => dispatch(doSearch(search))} >
                        <SearchIcon />
                    </Button>
                </InputGroup>
            </div>
        </div>
    )
}

export default Header;