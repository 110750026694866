import React from "react";
import Sidebar from "./Sidebar";
import {Navigate} from 'react-router-dom';

const PrivateRoutes = ({ auth, Component }) => {
  return (
    <>
      {auth ? (
        <Sidebar compoment={Component}/>
      ) : (
        <Navigate to={"/signup"} />
      )}
    </>
  );
};

export default PrivateRoutes;
