import React from "react";
const TextField = ({ placeholder, label, customId, register, errors, ...restProps }) => {
    const { color } = restProps;
    return (
        <div style={{ marginBottom: 14 }}>
            <label htmlFor={customId} className="text-label label-form" style={{ display: 'block', color: '#1346a3', fontWeight: "bold" }}>
                {label}
            </label>
            <input placeholder={placeholder} id={customId} {...restProps} {...register(customId)} className="text-field" />
            {errors && <p style={{ color: 'red' }}>{errors}</p>}
        </div>
    )
}
export default TextField;