import React, { useState, useRef, useEffect } from "react";
import {Typography} from '@mui/material';

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
} from "react-image-crop";
import { canvasPreview } from "./CanvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
        makeAspectCrop(
            {
                unit: "%",
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    );
}

export default function ImageCrop({ handleClose, setImageName }) {
    const inputRef=useRef(null);
    const [imgSrc, setImgSrc] = useState("");
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);
    const hiddenAnchorRef = useRef(null);
    const blobUrlRef = useRef("");
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();

    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined);
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                setImgSrc(reader.result?.toString() || ""),
            );
            reader.readAsDataURL(e.target.files[0]);
            setImageName(prev => ({ ...prev, name: e.target.files[0].name }))
        }
    }

    function onImageLoad(e) {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, 16 / 9));
    }

    async function onDownloadCropClick() {
        const image = imgRef.current;
        const previewCanvas = previewCanvasRef.current;
        if (!image || !previewCanvas || !completedCrop) {
            throw new Error("Crop canvas does not exist");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const offscreen = new OffscreenCanvas(
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
        );
        const ctx = offscreen.getContext("2d");
        if (!ctx) {
            throw new Error("No 2d context");
        }

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height,
        );

        const blob = await offscreen.convertToBlob({
            type: "image/png",
        });
        setImageName(prev => ({ ...prev, imageFile:new File([blob],prev.name,{type:'image/*'}) }));
        handleClose();


        // if (blobUrlRef.current) {
        //     URL.revokeObjectURL(blobUrlRef.current);
        // }
        // blobUrlRef.current = URL.createObjectURL(blob);
        // hiddenAnchorRef.current.href = blobUrlRef.current;
        // hiddenAnchorRef.current.click();
    }

    useDebounceEffect(
        async () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current &&
                previewCanvasRef.current
            ) {
                canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
            }
        },
        100,
        [completedCrop],
    );

    return (
        <div className="px-3">
          <Typography
                variant="h4"
                style={{ marginBottom: 10, textAlign: "center" }}>
                Crop Image
            </Typography>
            <div className="Crop-Controls">
                <button className="btn btn-primary mb-2 w-full" onClick={()=>inputRef.current.click()}>{!imgSrc?'select Image':'Change Image'}</button>
                <input type="file" accept="image/*" onChange={onSelectFile} hidden ref={inputRef}/>
            </div>
            {!!imgSrc && (
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={16 / 9}
                    minHeight={100}
                >
                    <img
                        ref={imgRef}
                        alt="Crop me"
                        src={imgSrc}
                        onLoad={onImageLoad}
                    />
                </ReactCrop>
            )}
            {!!completedCrop && (
                <div style={{display:"flex",justifyContent:'center',flexDirection:'column',alignItems:"center"}}>
                    <h5 style={{fontWeight:"bold"}}>Preview</h5>
                    <canvas
                        ref={previewCanvasRef}
                        style={{
                            border: "1px solid black",
                            objectFit: "cover",
                            width: completedCrop.width,
                            height: completedCrop.height,
                        }}
                    />
                </div>
            )}
            {completedCrop &&
                <div className="d-flex justify-content-end">
                    <button onClick={onDownloadCropClick} className="btn btn-primary mt-2">Save</button>
                </div>
            }
        </div>
    );
}
