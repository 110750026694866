import React, { useRef, useState } from 'react';
import SignUp from './Forms/SignUp';
import Login from './Forms/Login';

const Register = () => {
  const [showLogo, setShowLogo] = useState(true);

  return (
    <div className='user-register-form'>
      <div className="container">
        <div className="row">
{/*           {showLogo && <div className="col-md-12" style={{ display: 'flex', flexDirection: "column", alignItems: "center" }}> */}
{/*             <div> */}
{/*               <img src='/imgpsh_fullsize_anim.png' alt='Logo' style={{ width: 55, height: 60 }} /> */}
{/*             </div> */}
{/*             <h3> MijnData</h3> */}
{/*           </div>} */}
          <div className="col-md-12" >

            <div className="form-sign-in">

              {/* <!--bootstrap tabs--> */}
              <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => setShowLogo(true)}>Login</button>
                  <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => setShowLogo(false)}>Register</button>
                </div>
              </nav>
              <div class="tab-content p-3 border bg-white" id="nav-tabContent">
                <div class="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <div className="sign-form-user">
                    <Login />
                  </div>
                </div>
                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <div className="user-registers">
                    <SignUp />
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>
      </div>
    </div>

  )
}

export default Register