import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import SendIcon from '@mui/icons-material/Send';
import { useChatMutation } from "../app/services/jsonServerApi";
import BouncingDotsLoader from "./common/BouncingDotLoader";
import EntityDesc from "./EntityDesc";

const Chat = () => {
    const { data } = useSelector(state => state.document);
    const [input, setInput] = useState(null);
    const [show, setShow] = useState(true);
    const ref = useRef(null);
    const chatRef = useRef(null);
    const [chat, { isLoading }] = useChatMutation();
    const [message, setMessage] = useState([{ title: `Hi! How can I help you with ${data.fileName}?`, response: true }])

    const handleSubmit = () => {
        setMessage((state) => ([...state, { title: input }]));
        setInput(null);
        if (ref.current) {
            ref.current.value = ''
        }

        chat({ question: input, documentName: data.fileName, documentPath: data.uploadedFilePath }).then(res => {
            if (res.data?.answer) {
                setMessage(state => ([...state, { title: res.data.answer, response: true }]))
            }
        })

    }
    const scrollToBottom = () => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [JSON.stringify(message)])

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                if (input) {
                    handleSubmit()
                }
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [input]);

    return (
        <>
            <div style={{ height: "100%" }}>
                <div className="row" style={{ height: "100%" }}>
                    <div className={`col-lg-${show ? '9' : '12'}`} style={{ transition: "all 0.5s ease" }}>
                        <div className="chat-section">
                            <div className="chat-container">
                                <div className="chat-box" ref={chatRef}>
                                    {
                                        message.map((res, idx) => (
                                            <div style={{ display: "flex", alignItems: "baseline", flexDirection: !res.response ? "row-reverse" : "row"}}>
                                                {
                                                    <div style={{ width: "40px", height: "40px", borderRadius: "50%", overflow: "hidden",marginRight:res.response?'6px':"0px" }}>
                                                        <img src={res.response ? `${process.env.REACT_APP_BACKEND_URL}/${data.imagePath}` : "/user-img.jpg"} alt='documents' style={{ width: "70px", height: "46px", objectFit: "cover" }} />
                                                    </div>
                                                }
                                                <div className={`message ${res.response ? 'received' : 'sent'}`} key={idx}>
                                                    <span>{res.title}</span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {isLoading && <BouncingDotsLoader />}
                                </div>
                                <div className="chat-form">
                                    <input type="text" ref={ref} id="chat-input" onChange={(e) => setInput(e.target.value)} placeholder="Type your message..." title="Type your message and press enter to send" />
                                    <button type="submit" className="send-button" title="Send message" onClick={handleSubmit} on disabled={!input}>
                                        <SendIcon /> Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        show && <div className="col-lg-3 col-md-4" style={{ transition: "all 0.5s ease" }}>
                            <EntityDesc setShow={setShow} />
                        </div>
                    }
                </div>

            </div>
        </>
    )
}

export default Chat;