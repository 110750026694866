import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link,useNavigate } from "react-router-dom";
import { SidebarHeader } from "./SideBarHeader";
import { Typography, Switch } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import {
  Home
} from "@mui/icons-material";
import CatchingPokemonIcon from '@mui/icons-material/CatchingPokemon';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ChatIcon from '@mui/icons-material/Chat';
// import UserImg from "../../public/profile.avif"
import Header from "./Header";
import { hexToRgba, themes } from "../service";
import { useAuth } from '../hooks/useAuth';
import { doLogout } from "../reducers/app";
import { notification } from '../service/index';
import { setCollapse } from "../reducers/sidebar";

const SidebarPage = ({ compoment }) => {
  const { pathname } = useLocation();
  const navigate=useNavigate()
  const { isAdmin } = useAuth();
  const [theme, setTheme] = useState("light");
  const [showMenu, setShowMenu] = useState(false);
  const { collapsed } = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();
  const [toggled, setToggled] = React.useState(false);
  
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('document');
    dispatch(doLogout());
    notification({ message: 'Logout Successfully!' })
    navigate('/signup');
  }

  const menuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.ps-disabled`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes[theme].menu.menuContent, 1)
          : "transparent",
    }),
    button: {
      [`&.ps-disabled`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, 1),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };


  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        width: "100%",
        boxSizing: "border-box",
      }} id="wrapper">
      <Sidebar className="sidebar_main"
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        breakPoint="md"
        backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, 1)}
        rootStyles={{
          color: themes[theme].sidebar.color,
          height: "100vh",
          overflowY: "auto",
          width: "15%"
        }}>
        <SidebarHeader
          style={{ marginBottom: "24px", marginTop: "16px" }}
          theme={theme}
          setTheme={setTheme}
          collapsed={collapsed}
        />
        <div style={{ flex: 1, marginBottom: "32px" }} className="sidebar-holder">
          <div style={{ padding: "0 24px", marginBottom: "8px" }}>
            <Typography
              variant="body2"
              fontWeight={600}
              style={{
                opacity: collapsed ? 0 : 0.7,
                letterSpacing: "0.5px",
              }}>
              {/* General */}
            </Typography>
          </div>
          <Menu className="side_menu" menuItemStyles={menuItemStyles}>
            <MenuItem component={<Link to="/" />} icon={<Home />} className={`${pathname === '/' ? 'active-menu' : ''}`}>
              Dashboard
            </MenuItem>

            {isAdmin &&
              <MenuItem component={<Link to="/uploadDoc" />} icon={<DriveFolderUploadIcon />} className={`${pathname === '/uploadDoc' ? 'active-menu' : ''}`}>
                Upload Document
              </MenuItem>
            }

            <MenuItem component={<Link to="/#" />} icon={<CatchingPokemonIcon />}>
              Projects
            </MenuItem>

            <MenuItem component={<Link to="/" />} icon={<ListAltIcon />}>
              Task List
            </MenuItem>

            <MenuItem component={<Link to="/" />} icon={<AutoModeIcon />}>
              Services
            </MenuItem>

            <MenuItem component={<Link to="/" />} icon={<CircleNotificationsIcon />}>
              Notifications
            </MenuItem>

            <MenuItem component={<Link to="/" />} icon={<ChatIcon />}>
              Chat
            </MenuItem>
          </Menu>

          <div className="user-profile">
            <div className="user-img position-relative">
              <img src="/user-img.jpg" alt="" style={{ cursor: "pointer" }} onClick={() => {
                    setShowMenu(!showMenu);
              }} />
              {showMenu && <div className="profile-menu">
                <span role="button" className="menu-btn" onClick={() => handleLogout()}>Logout</span>
              </div>}
            </div>
            <div className="user-details">
              <h2>Adam Jack</h2>
              <p><a href="mailto:test@gmail.com">test@gmail.com</a></p>
            </div>
          </div>
        </div>
        {/* <div
            style={{
              paddingTop: 20,
              display: "flex",
              flexDirection: collapsed ? "column" : "row",
              justifyContent: "space-around",
              alignItems: "center",
            }}>
            {!collapsed && <Typography>Collapse</Typography>}
            <Switch
              id="collapse"
              checked={collapsed}
              onChange={() => dispatch(setCollapse({ collapsed: !collapsed }))}
              label="Collapse"
            />
          </div> */}

      </Sidebar>
      <div className="right_main_div" style={{ width: collapsed ? "96%" : "84%", }}>
        <Header />
        <div className="content-container" id="items-loaded">{compoment}</div>
      </div>
    </div>
  );
};

export default SidebarPage;
