import React,{useEffect} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import { useLoginUserMutation } from "../../app/services/jsonServerApi";
import { doLogin } from "../../reducers/app";
import { notification } from "../../service/index";
import CustomLink from "../common/CustomLink";
import SubmitButton from "../common/SubmitButton";
import TextField from "../common/TextField";
import { useSelector } from "react-redux";

const schema = yup.object({
  email: yup.string().email().required("Please Enter email"),
  password: yup
    .string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
});

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginUser, { isLoading,error }] = useLoginUserMutation();
  const {token,user}=useSelector(state=>state.app);

  function onFormSubmit(v) {
    loginUser(v)
      .then((value) => {
        if (value?.error?.status === 401) {
          notification({
            title: "Failed",
            message: value?.error?.data?.message,
            type: "danger",
          });
        } else if (value?.data?.result?.statusCode === 200) {
          const {
            data: {
              result: { user },
              token,
            },
          } = value;
          notification({
            title: "Login Success",
            message: "Login Successfully!",
            type: "success",
          });
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(user));
          dispatch(doLogin({ token, user }));
          navigate("/");
        } else if (value?.error) {
          notification({
            title: "Error",
            message: value?.error?.status,
            type: "danger",
          });
        }
      })
  }

  return (
    <div className="login-signup-bg" style={{ paddingTop: "50px" }}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <div className="form-wrapper">
            {/* <Typography
              variant="h4"
              style={{
                marginBottom: 10,
                textAlign: "center",
                color: "#1346a3",
              }}>
              Login Form
            </Typography> */}
            <form className="login-form" onSubmit={handleSubmit(onFormSubmit)}>
              <TextField
                label="Email"
                placeholder="Enter your email"
                customId="email"
                register={register}
                errors={errors?.email?.message}
              />
              <TextField
                label="Password"
                placeholder="Enter your password"
                customId="password"
                register={register}
                type="password"
                errors={errors?.password?.message}
              />
           
              <SubmitButton label="Login" />

              <CustomLink
                link={"#"}
                text="Wachtwoord vergeten?"
                label={"Rest het hier"}
              />

            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default Login;
